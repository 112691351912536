import React from "react";
import logo_brand from "../assets/logo_brand.svg";
import botImg1 from "../assets/bot_1.png";
import botImg2 from "../assets/bot_2.png";
import FormPage from "./FormPage"
import styled from 'styled-components';


// const


export default function HomePage() {
    return (
        <div>
            <div style={{position: "absolute", right: 0, bottom: 0}}>
                <img style={{height: "70vh"}} src={botImg2} alt="logo"/>
            </div>
            <div style={{position: "absolute", right: "20%", bottom: 0}}>
                <img style={{height: "70vh"}} src={botImg1} alt="logo"/>
            </div>
            <div className="HomePageScreen" style={{minHeight: "100vh"}}>
                <div style={{scrollBehavior: "initial"}}>
                    <img src={logo_brand} alt={"Brand"} className="LogoBrand"/>
                    <div className="PageTitle">
                        <div>One of a Kind</div>
                        <div>Part of a Kind</div>
                    </div>
                    <div className="HomeContent">
                        An AI-powered safe space that helps you understand
                        and enhance the impression you make on others, and then
                        find people whose impression resonates with you.
                    </div>
                </div>
                <div style={{position: "absolute", top: 20, right: 20, color: "white"}}>
                    LAUNCHING IN Q3 2024 ON IOS
                </div>
            </div>
            <FormPage/>
        </div>
    );
}