import HomePage from "./pages/HomePage"
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  return (
      <Router>
    < HomePage />
      </Router>
  );
}

export default App;
